<template>
  <transition name="fade-in-up">
    <router-view ref="payInfoRouterView"></router-view>
  </transition>
</template>

<script>
export default {
  components: {},
  methods: {
    updateView() {
      this.$refs.payInfoRouterView.updateView();
    },
  },
};
</script>
